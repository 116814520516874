.muti-image-body {
    width: 460px;

    .muti-image-item {
        position: relative;
        margin-bottom: 20px;

        .muti-image-item-title {
            margin-bottom: 6px;
        }

        .delete-image-item {
            position: absolute;
            top: -0;
            right: -20px;
        }
    }
}
@primary-color: #7f7fd5;@link-color: #86a8e7;