.muti-image-body {
  width: 460px;
}
.muti-image-body .muti-image-item {
  position: relative;
  margin-bottom: 20px;
}
.muti-image-body .muti-image-item .muti-image-item-title {
  margin-bottom: 6px;
}
.muti-image-body .muti-image-item .delete-image-item {
  position: absolute;
  top: 0;
  right: -20px;
}
